*, *::before, *::after {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], figure, blockquote, dl, dd {
  margin: 0;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

ul[class], ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
* {
  margin: 0;
  padding: 0;
}

.pc {
  display: block !important;
}

.sp {
  display: none !important;
}

@media (max-width: 767px) {
  .pc {
    display: none !important;
  }
  .sp {
    display: block !important;
  }
}
.u-relative {
  position: relative;
}

.u-absolute {
  position: absolute;
}

.u-inner {
  max-width: var(--inner);
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767px) {
  .u-inner {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.u-cover {
  object-fit: cover;
}

.u-white {
  color: #fff;
}

.u-bg-white {
  background-color: #fff;
}

.u-grid {
  display: grid;
  grid-template-columns: var(--col);
  gap: var(--gap);
}
@media (max-width: 767px) {
  .u-grid {
    grid-template-columns: var(--col_sp);
    gap: var(--gap_sp);
  }
}

.u-bold {
  font-weight: bold;
}

.u-mb-24 {
  margin-bottom: 2.4rem;
}

.u-mb-32 {
  margin-bottom: 3.2rem;
}

.c-message {
  font-family: "Zen Kaku Gothic New";
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 260%; /* 41.6px */
}

.c-title-32 {
  font-family: "Zen Kaku Gothic New";
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 48px */
}

.c-title-24 {
  font-family: "Zen Kaku Gothic New";
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 36px */
}

.c-text-16 {
  font-family: "Zen Kaku Gothic New";
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 185%; /* 29.6px */
}

.c-text-14 {
  font-family: "Zen Kaku Gothic New";
  font-size: 14px;
  font-weight: 400;
  line-height: 185%; /* 25.9px */
}

.c-gallery-wrap {
  overflow-x: clip;
  padding: 6rem 0 9rem;
  display: flex;
  gap: 4rem;
}
@media (max-width: 767px) {
  .c-gallery-wrap {
    padding: 4rem 0 4.8rem;
    gap: 2rem;
  }
}

.c-gallery {
  display: flex;
  gap: 4rem;
  animation: scroll-left 25s infinite linear 0.5s both;
}
@media (max-width: 767px) {
  .c-gallery {
    gap: 2rem;
  }
}
@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.c-gallery__item {
  position: relative;
  width: 36.4rem;
  flex: 0 0 36.4rem;
  top: 6rem;
}
@media (max-width: 767px) {
  .c-gallery__item {
    width: 22rem;
    flex: 0 0 22rem;
  }
}
.c-gallery__item:nth-child(even) {
  top: 0;
}
.c-gallery__item-accent {
  position: absolute;
}
@media (max-width: 767px) {
  .c-gallery__item-accent {
    scale: 0.65;
  }
}
.c-gallery__item-accent--1 {
  width: 15rem;
  bottom: -3rem;
  right: -5.3rem;
}
.c-gallery__item-accent--2 {
  left: -4rem;
  top: -1.6rem;
  width: 8.5rem;
}
.c-gallery__item-accent--3 {
  left: -2.6rem;
  top: -2.3rem;
  width: 6.4rem;
}
.c-gallery__item-accent--4 {
  right: -4rem;
  bottom: -1.6rem;
  width: 8.6rem;
}
.c-gallery__item-accent--5 {
  top: -6rem;
  right: -5.6rem;
  width: 9.4rem;
}
.c-gallery__item-accent--6 {
  left: -4rem;
  bottom: -3rem;
  width: 9.8rem;
}
.c-gallery__item-accent--7 {
  right: -4rem;
  bottom: -1.6rem;
  width: 8.5rem;
}
.c-gallery__item-accent--8 {
  left: -4rem;
  top: -1.6rem;
  width: 8.5rem;
}

.c-headline {
  width: fit-content;
  margin: auto;
}

.c-button {
  border-radius: 9rem;
  border: 1px solid var(--border, #2c2c2c);
  width: 30rem;
  height: 7.2rem;
  padding: 0.6rem;
  display: block;
  position: relative;
}
.c-button:hover {
  padding: 0;
}
.c-button__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 9rem;
  outline: 1px solid var(--border, #2c2c2c);
  background: #e0eef5;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 185%; /* 29.6px */
}
.c-button__icon {
  position: absolute;
  width: 4rem;
  height: 4rem;
  right: 1rem;
  top: 50%;
  transform: translate(0, -50%);
}

.c-cta {
  display: grid;
  grid-template-columns: 27.7777777778vw 1fr 27.7777777778vw;
}
@media (max-width: 767px) {
  .c-cta {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "body ." "image1 image2";
  }
}
.c-cta__body {
  background: #2c2c2c;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: solid 1px #2C2C2C;
}
@media (max-width: 767px) {
  .c-cta__body {
    grid-area: body;
    grid-column: span 2;
    padding: 4rem 2rem;
  }
}
.c-cta__image1 img {
  border: solid 1px #2C2C2C;
}
@media (max-width: 767px) {
  .c-cta__image1 {
    grid-area: image1;
  }
  .c-cta__image1 img {
    width: 100%;
    height: 15.6rem;
    object-fit: cover;
  }
}
.c-cta__image2 img {
  border: solid 1px #2C2C2C;
}
@media (max-width: 767px) {
  .c-cta__image2 {
    grid-area: image2;
  }
  .c-cta__image2 img {
    width: 100%;
    height: 15.6rem;
    object-fit: cover;
  }
}
.c-cta__logo {
  width: 26.4rem;
  margin: 0 auto 1.6rem;
}
@media (max-width: 767px) {
  .c-cta__logo {
    width: 20.2rem;
    margin: 0 auto 1.6rem;
  }
}
.c-cta__title {
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 36px */
  text-align: center;
  color: #fff;
  margin-bottom: 3.6rem;
}
@media (max-width: 767px) {
  .c-cta__title {
    font-size: 2rem;
    margin-bottom: 3.2rem;
  }
}
.c-cta__button {
  width: 40rem;
  border-color: #fff;
}
@media (max-width: 767px) {
  .c-cta__button {
    width: 29.2rem;
    height: 6.8rem;
  }
}
.c-cta__button-inner {
  background-color: #fff;
  color: #2c2c2c;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 150%;
}
@media (max-width: 767px) {
  .c-cta__button-inner {
    font-size: 1.6rem;
  }
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
@media screen and (max-width: 1279px) {
  html {
    font-size: 0.6944444444vw;
  }
}
@media screen and (max-width: 1079px) {
  html {
    font-size: 40%;
  }
}
@media screen and (max-width: 767px) {
  html {
    font-size: 2.5641025641vw;
  }
}

:root {
  --black: #2C2C2C;
  --color: var(--black);
  --primary-color: #f2b035;
  --secondary-color: #36bfb8;
  --tertiary-color: #09ccea;
  --quaternary-color: #561912;
  --error-color: #bb491d;
  --gray: #707070;
  --primary-bg-color: #fff;
  --primary-button-color: #282a32;
  --font: "Zen Kaku Gothic New", sans-serif;
  --primary-font: "Domine", serif;
  --font-size: 1.6rem;
  --font-size-xs: 1.2rem;
  --font-size-s: 1.4rem;
  --font-size-base: var(--font-size);
  --font-size-m: 2rem;
  --font-size-l: 2.4rem;
  --font-size-xl: 2.8rem;
  --font-size-2xl: 3.2rem;
  --font-size-3xl: 3.6rem;
  --font-size-4xl: 6.3rem;
  --font-size-5xl: 6.3rem;
  --gutter: 1.5rem;
  --inner: 119.6rem;
  --transition1: cubic-bezier(0.75, 0, 0.175, 1);
}

body {
  font-feature-settings: "palt";
  font-family: var(--font);
  font-size: var(--font-size);
  color: var(--color);
  background-color: var(--primary-bg-color);
  line-height: 1.5;
  position: relative;
}
b {
  font-weight: bold;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;
}
a:not(.c-button):hover {
  opacity: 0.7;
}

input[type=submit] {
  transition: 0.3s;
  cursor: pointer;
}
input[type=submit]:hover {
  opacity: 0.7;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

p {
  font-size: var(--font-size);
  line-height: 2;
  color: var(--text);
}
@media (max-width: 767px) {
  p {
    font-size: 1.4rem;
    line-height: 3rem;
  }
}

img {
  max-width: 100%;
  display: block;
  height: auto;
}

video {
  max-width: 100%;
}

button {
  appearance: none;
  border: 0;
}

.header__inner {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 9rem;
  background-color: #fff;
  transition: 0.4s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6rem;
  transform: translate(0, -101%);
  border-bottom: 1px solid #2C2C2C;
}
@media (max-width: 767px) {
  .header__inner {
    padding: 0 2rem;
    height: 6.4rem;
  }
}
.header__inner.is-change {
  transform: translate(0, 0);
}
.header__inner.is-active {
  transform: translate(0, -100%);
}
@media (max-width: 767px) {
  .header__logo {
    width: 5.8rem;
  }
}
.header__drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10001;
  background: rgba(44, 44, 44, 0.6);
  transform: translate(100%, 0);
  transition-delay: 0.4s;
  transition: 0.6s;
}
.header__drawer.is-active {
  transform: none;
}
.header__button {
  font-size: 1.8rem;
  font-weight: 500;
  cursor: pointer;
  font-family: "Classico URW";
}
@media (max-width: 767px) {
  .header__button {
    font-size: 1.4rem;
  }
}
.header__button:hover::after {
  scale: 1.4;
}
.header__button::after {
  transition: 0.4s;
  content: "";
  width: 0.8rem;
  height: 0.8rem;
  background-color: #2C2C2C;
  border-radius: 100%;
  margin-left: 2rem;
  display: inline-block;
}
@media (max-width: 767px) {
  .header__button::after {
    margin-left: 0.8rem;
    width: 0.6rem;
    height: 0.6rem;
  }
}
.header__drawer-button {
  position: absolute;
  top: 0;
  right: 6rem;
  height: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 500;
  cursor: pointer;
  font-family: "Classico URW";
}
@media (max-width: 767px) {
  .header__drawer-button {
    font-size: 1.4rem;
    top: 1.6rem;
    right: 2rem;
    height: 3.5rem;
  }
}
.header__drawer-button:hover::after {
  scale: 1.4;
}
.header__drawer-button::after {
  transition: 0.4s;
  line-height: 4.5rem;
  content: "";
  width: 0.8rem;
  height: 0.8rem;
  background-color: #fff;
  border-radius: 100%;
  margin-left: 2rem;
  display: inline-block;
  position: relative;
  top: 0.3rem;
}
@media (max-width: 767px) {
  .header__drawer-button::after {
    margin-left: 0.8rem;
    width: 0.6rem;
    height: 0.6rem;
    top: 0;
  }
}
.header__drawer-close {
  position: absolute;
  width: 100%;
  height: 100%;
}
.header__drawer-content {
  width: 60rem;
  height: 100%;
  background-color: #2C2C2C;
  color: #fff;
  margin-left: auto;
  position: relative;
  z-index: 2;
}
@media (max-width: 767px) {
  .header__drawer-content {
    width: 100%;
    padding: 2rem;
  }
}
@media print, screen and (min-width: 768px) {
  .header__drawer-links-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .header__drawer-links-wrap {
    margin-top: 15.8rem;
  }
}
.header__drawer-links-wrap .footer__sns {
  position: absolute;
  left: 6rem;
  bottom: 4rem;
}
@media (max-width: 767px) {
  .header__drawer-links-wrap .footer__sns {
    position: relative;
    bottom: auto;
    left: auto;
    text-align: left;
    display: flex;
    margin-top: 6.4rem;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .header__drawer-links-wrap .footer__sns-title {
    margin-bottom: 0;
  }
}
.header__drawer-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem 6rem;
}
@media (max-width: 767px) {
  .header__drawer-links {
    gap: 2rem 3.2rem;
  }
}
.header__drawer-link {
  color: #FFF;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 1.2rem;
}
@media (max-width: 767px) {
  .header__drawer-link {
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
  }
}
.header__drawer-link-title {
  margin-bottom: 1.2rem;
  font-family: "Classico URW";
  text-transform: uppercase;
}
.header__drawer-link-subtitle {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
}
@media (max-width: 767px) {
  .header__drawer-link-subtitle {
    font-size: 1.2rem;
  }
}

.footer {
  text-align: center;
  color: #fff;
  background-color: #000;
  font-family: "Classico URW";
}
.footer__inner {
  max-width: 128rem;
  margin-left: auto;
  margin-right: auto;
}
.footer__copyright {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: 0.05em;
  padding: 2.4rem;
}
@media (max-width: 767px) {
  .footer__copyright {
    padding: 1.6rem;
  }
}
.footer__row {
  padding: 8rem 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
@media (max-width: 767px) {
  .footer__row {
    padding: 6.4rem 0 8rem;
    display: block;
  }
}
@media (max-width: 767px) {
  .footer__logo {
    width: 12rem;
    margin: 0 auto 5.6rem;
  }
}
.footer__sns {
  font-family: "Classico URW";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 22.5px */
  display: flex;
  gap: 6.2rem;
  align-items: center;
}
@media (max-width: 767px) {
  .footer__sns {
    display: block;
    text-align: center;
  }
  .footer__sns-title {
    margin-bottom: 4rem;
  }
}
.footer__sns-lists {
  display: flex;
  align-items: center;
  gap: 3.2rem;
}
@media (max-width: 767px) {
  .footer__sns-lists {
    justify-content: center;
  }
}

.float-btn {
  position: fixed;
  right: 4rem;
  bottom: 4rem;
  z-index: 1000;
  width: 14rem;
  height: 14rem;
}
@media (max-width: 767px) {
  .float-btn {
    width: 10rem;
    height: 10rem;
    bottom: 2rem;
    right: 2rem;
  }
}
